<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Body -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Ubah Status Billing Massal</h4>
          </template>
          <template v-slot:body>
            <!-- BEGIN:Form -->
            <div class="align-items-center p-4">
              <b-form>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Format Form:"
                  label-for="formatForm"
                >
                  <b-button
                    class="btn-block w-25"
                    variant=" iq-bg-dark"
                    type="button"
                    href="/docs/template_form.csv"
                    download
                    ><i class="ri-file-download-line"></i>Download
                    Form</b-button
                  >
                </b-form-group>
                <b-form-group
                  class="row pt-2"
                  label-cols-sm="2"
                  label="Dokumen:"
                  label-for="dokumen"
                >
                  <b-form-file
                    placeholder="Pilih Dokumen"
                    id="dokumen"
                    v-model="form.dokumen"
                    required
                  ></b-form-file>
                </b-form-group>
                <!-- Button -->
                <b-row class="pt-5 justify-content-center">
                  <b-button
                    class="btn-block w-25"
                    variant="primary"
                    type="button"
                    @click="createData"
                    :disabled="!isValidForm"
                    ><i class="ri-file-upload-line"></i>Proses</b-button
                  >
                </b-row>
              </b-form>
            </div>
            <!-- ./END:Form -->
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Body -->
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Vue from 'vue'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'kerjasama.ubahStatusBillingMassal',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status = this.form.dokumen !== null
      return status
    }
  },
  async mounted() {
    /** Mounted code here... */
  },
  methods: {
    /**
     * Create Data
     */
    async createData() {
      const bodyFormData = new FormData()
      bodyFormData.append('file', this.form.dokumen)
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/flaggingAdmisi/prosesFlaggingAdmisiMassal`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err.response)
          this.$toast.error(err?.response?.data?.message)
          throw new Error(err)
        })
    }
  },
  data() {
    return {
      detailData: false,
      // Form
      form: {
        dokumen: null
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (
      MENU_ROLE.KERJASAMA_SERBAGUNA_BILLING_MASSAL.includes(String(idUnitKs))
    ) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
  }
}
</script>
